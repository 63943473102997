import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`14th May 2020`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Added `}<inlineCode parentName="p">{`Server.blockUntilShutdown()`}</inlineCode>{` which lets you wait for a `}<a parentName="p" {...{
            "href": "type://Server:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html"
          }}>{`type://Server`}</a>{` to close. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2659"
          }}>{`#2659`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server server = Server.builder()...build();
server.start().join();
// Returns when server.close() or closeAsync() is called.
server.blockUntilShutdown();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added `}<inlineCode parentName="p">{`SystemInfo.defaultNonLoopbackIpV4Address()`}</inlineCode>{` which returns the sensible IPv4 address of the current
machine. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2679"
          }}>{`#2679`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify authorization credentials conveniently when building a client: `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2680"
          }}>{`#2680`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`WebClient.builder("https://example.com/api")
         // Basic
         .auth(BasicToken.of("admin", "sesame"))
         // OAuth 1a
         .auth(OAuth1aToken.of(...))
         // OAuth 2
         .auth(OAuth2Token.of("token-0123456789abcdef"))
         .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`New `}<a parentName="p" {...{
            "href": "type://RetryRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRule.html"
          }}>{`type://RetryRule`}</a>{` and `}<a parentName="p" {...{
            "href": "type://RetryRuleWithContent:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRuleWithContent.html"
          }}>{`type://RetryRuleWithContent`}</a>{` API let you define a complex retry policy
conveniently. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1634"
          }}>{`#1634`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2661"
          }}>{`#2661`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2709"
          }}>{`#2709`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Backoff myBackoff = Backoff.exponential(1000, 60000)
                           .withJitter(0.3)
                           .withMaxAttempts(10);
RetryRule retryRule =
    RetryRule.of(RetryRule.builder()
                          .onUnProcessed()
                          .thenBackoff(myBackoff),
                 RetryRule.builder()
                          .onException(ResponseTimeoutException.class)
                          .thenBackoff(),
                 RetryRule.builder()
                          .onStatus(HttpStatus.TOO_MANY_REQUESTS)
                          .thenNoRetry())

RetryingClient.newDecorator(retryRule);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://CircuitBreakerListener:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerListener.html"
          }}>{`type://CircuitBreakerListener`}</a>{` now has a new event listener method `}<inlineCode parentName="p">{`onInitialized()`}</inlineCode>{`, which is invoked
when a circuit is initialized. By default, it simply delegates to `}<inlineCode parentName="p">{`onStateChanged()`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2675"
          }}>{`#2675`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now register your server to or retrieve the `}<a parentName="p" {...{
            "href": "typeplural://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
          }}>{`typeplural://Endpoint`}</a>{` from
a `}<a parentName="p" {...{
            "href": "https://spring.io/guides/gs/service-registration-and-discovery/"
          }}>{`Eureka`}</a>{` registry server,
which gives nice integration with Spring Cloud. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/707"
          }}>{`#707`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2701"
          }}>{`#2701`}</a></p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Use `}<a parentName="p" {...{
                "href": "type://EurekaUpdatingListener:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/eureka/EurekaUpdatingListener.html"
              }}>{`type://EurekaUpdatingListener`}</a>{` for `}<a parentName="p" {...{
                "href": "typeplural://Server:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html"
              }}>{`typeplural://Server`}</a>{`:`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`Server server = Server.builder()...build();
server.addListener(EurekaUpdatingListener.of("https://my-eureka-registry.com/"));
// Automatically registers when the Server starts.
server.start().join();
// Automatically deregisters when the Server stops.
server.stop().join();
`}</code></pre>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Use `}<a parentName="p" {...{
                "href": "type://EurekaEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/eureka/EurekaEndpointGroup.html"
              }}>{`type://EurekaEndpointGroup`}</a>{` for clients:`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`// Periodically retrieves the endpoint list from the registry.
EndpointGroup group = EurekaEndpointGroup.of("https://my-eureka-registry/");
group.whenReady().join();

// Sends a request to the endpoints, spreading the load w/ weighted round-robin.
WebClient client = WebClient.of(SessionProtocol.HTTP, group);
System.err.println(client.get("/some-resource")
                         .aggregate().join()
                         .contentUtf8());
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use the gRPC client stubs generated by `}<a parentName="p" {...{
            "href": "https://github.com/grpc/grpc-kotlin"
          }}><inlineCode parentName="a">{`grpc-kotlin`}</inlineCode></a>{`
`}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2662"
          }}>{`#2662`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2669"
          }}>{`#2669`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added a new Thrift serialization format `}<inlineCode parentName="p">{`TTEXT_NAMED_ENUM`}</inlineCode>{`. It is a variant of `}<inlineCode parentName="p">{`TTEXT`}</inlineCode>{` that serializes
an `}<inlineCode parentName="p">{`enum`}</inlineCode>{` as a string instead of an integer. This can be useful when you need to serialize a `}<inlineCode parentName="p">{`TBase`}</inlineCode>{`
object into a human-readable JSON. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2706"
          }}>{`#2706`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ObjectMapper mapper = new ObjectMapper()
mapper.registerModule(new ThriftJacksonModule(/* useNamedEnum */ true));
String json = mapper.writeValueAsString(myTBaseStruct);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added two Spring Boot annotations that inject the current local Armeria port(s) - `}<a parentName="p" {...{
            "href": "type://@LocalArmeriaPort:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/LocalArmeriaPort.html"
          }}>{`type://@LocalArmeriaPort`}</a>{` and `}<a parentName="p" {...{
            "href": "type://@LocalArmeriaPorts:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/LocalArmeriaPorts.html"
          }}>{`type://@LocalArmeriaPorts`}</a>{`,
which is useful when you bind at an ephemeral port or more than one port: `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2561"
          }}>{`#2561`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2684"
          }}>{`#2684`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Configuration
class MyConfiguration {
    @LocalArmeriaPort(SessionProtocol.HTTP)
    int httpPort;
    @LocalArmeriaPort(SessionProtocol.HTTPS)
    int httpsPort;
    @LocalArmeriaPorts
    List<Integer> ports;
}
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ZooKeeperUpdatingListener:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/zookeeper/ZooKeeperUpdatingListener.html"
        }}>{`type://ZooKeeperUpdatingListener`}</a>{` now updates the registry with both host name and IP address by default,
so that the client does not have to do a DNS lookup. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2674"
        }}>{`#2674`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2679"
        }}>{`#2679`}</a>
        <ul parentName="li">
          <li parentName="ul">{`The IP address is retrieved from `}<inlineCode parentName="li">{`defaultNonLoopbackIpV4Address()`}</inlineCode>{` of `}<a parentName="li" {...{
              "href": "type://SystemInfo:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/SystemInfo.html"
            }}>{`type://SystemInfo`}</a>{`.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`Fixed a bug where more than one thread can consume from a streaming message. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2687"
        }}>{`#2687`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2683"
        }}>{`#2683`}</a></li>
      <li parentName="ul">{`You will not get a `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` anymore when starting a `}<a parentName="li" {...{
          "href": "type://JettyService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/jetty/JettyService.html"
        }}>{`type://JettyService`}</a>{` with DEBUG level
logging enabled. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2688"
        }}>{`#2688`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2691"
        }}>{`#2691`}</a></li>
      <li parentName="ul">{`PING interval now works as expected even if idle timeout is disabled. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2702"
        }}>{`#2702`}</a></li>
      <li parentName="ul">{`A connection is now closed immediately when PING fails, rather than waiting for idle timeout. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2699"
        }}>{`#2699`}</a></li>
      <li parentName="ul">{`A request sent to an HTTP/1 server behind an HTTP CONNECT proxy server is now handled properly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2705"
        }}>{`#2705`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2698"
        }}>{`#2698`}</a></li>
      <li parentName="ul">{`An event loop does not block for too long anymore when:`}
        <ul parentName="li">
          <li parentName="ul">{`Thrift TEXT protocol loads a class. `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/2692"
            }}>{`#2692`}</a></li>
          <li parentName="ul">{`A client makes a lot of requests for a short period. `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/2700"
            }}>{`#2700`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://BasicToken:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/auth/BasicToken.html"
        }}>{`type://BasicToken`}</a>{`, `}<a parentName="li" {...{
          "href": "type://OAuth1aToken:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/auth/OAuth1aToken.html"
        }}>{`type://OAuth1aToken`}</a>{` and `}<a parentName="li" {...{
          "href": "type://OAuth2Token:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/auth/OAuth2Token.html"
        }}>{`type://OAuth2Token`}</a>{` have been moved to
`}<inlineCode parentName="li">{`com.linecorp.armeria.common.auth`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2680"
        }}>{`#2680`}</a></li>
      <li parentName="ul">{`Builder and factory methods of `}<a parentName="li" {...{
          "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
        }}>{`type://RetryingClient`}</a>{` need an explicit type parameter for
`}<a parentName="li" {...{
          "href": "type://RetryStrategy"
        }}>{`type://RetryStrategy`}</a>{` when creating with a lambda expression. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2661"
        }}>{`#2661`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Dropwizard 2.0.5 → 2.0.9`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.1.5 → 4.1.7, 3.2.5 → 3.2.6`}</li>
      <li parentName="ul">{`gRPC 1.28.0 → 1.29.0`}</li>
      <li parentName="ul">{`Jackson 2.10.3 → 2.11.0`}</li>
      <li parentName="ul">{`java-jwt 3.10.2 → 3.10.3`}</li>
      <li parentName="ul">{`Jetty 9.4.27 → 9.4.28`}</li>
      <li parentName="ul">{`Micrometer 1.3.6 → 1.5.1`}</li>
      <li parentName="ul">{`Netty 4.1.48 → 4.1.50`}</li>
      <li parentName="ul">{`Prometheus simpleclient 0.8.1 → 0.9.0`}</li>
      <li parentName="ul">{`protobuf-jackson 1.0.0 → 1.1.0`}</li>
      <li parentName="ul">{`Reactor 3.3.4 → 3.3.5`}</li>
      <li parentName="ul">{`RxJava 3.0.1 → 3.0.3`}</li>
      <li parentName="ul">{`snappy-java 1.1.7 → 1.1.7.5`}</li>
      <li parentName="ul">{`Spring Boot 2.2.6 → 2.2.7`}</li>
      <li parentName="ul">{`Tomcat 9.0.33 → 9.0.35, 8.5.53 → 8.5.55`}</li>
      <li parentName="ul">{`ZooKeeper 3.5.7 → 3.5.8`}</li>
      <li parentName="ul">{`Shaded dependencies`}
        <ul parentName="li">
          <li parentName="ul">{`Bouncy Castle 1.64 → 1.65`}</li>
          <li parentName="ul">{`Caffeine 2.8.1 → 2.8.2`}</li>
          <li parentName="ul">{`Guava 28.2 → 29.0`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Example dependencies`}
        <ul parentName="li">
          <li parentName="ul">{`Kotlin 1.3.71 → 1.3.72`}</li>
          <li parentName="ul">{`Kotlin Coroutines 1.3.4 → 1.3.6`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['andrewoma', 'anuraaga', 'Charliocat', 'eonezhang', 'gary-lo', 'ghkim3221', 'heowc', 'hexoul', 'hueneburg', 'ikhoon', 'imasahiro', 'jrhee17', 'KarboniteKream', 'max904-github', 'minwoox', 'mono0x', 'renaudb', 'shakuzen', 'syleeeee', 'tobias-', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      